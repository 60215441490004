<template>
  <div id="contact" class="panel is-form-page animate-fadeUp">
    <h1 class="title is-spaced is-size-3-desktop is-size-4-mobile">Thanks for taking the time to reach out</h1>
    <h2 class="subtitle is-size-5-desktop">Or meet me on social networks <i class="fas fa-reply-all" data-fa-transform="rotate-130" aria-hidden="true"></i></h2>
    <div id="contact-form" class="container is-fluid">
      <form id="invisible-recaptcha-form" accept-charset="UTF-8" action="https://formspree.io/mwkrgdyn" method="POST">
        <div class="columns is-centered">
          <div class="column is-half">
            <div class="field">
              <label class="label">Name</label>
              <div class="control is-expanded">
                <input class="input is-large" name="name" type="text" required="">
              </div>
            </div>
          </div>
          <div class="column is-half">
            <div class="field">
              <label class="label">Email</label>
              <div class="control is-expanded">
                <input class="input is-large" name="email" type="email" required="">
              </div>
            </div>
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column">
            <div class="field">
              <label class="label">Message</label>
              <div class="control is-expanded">
                <textarea class="textarea is-large" name="message" rows="5" required=""></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column is-one-third">
            <div class="field">
              <div class="control">
                <button class="button is-outlined is-medium is-fullwidth is-gl-blue">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<style lang="scss">
#contact-form {
  text-align: left;
}

.animate-fadeUp {
    -webkit-animation: fadeIn 2s, translate-up 1s;
    animation: fadeIn 2s, translate-up 1s;
}

.is-form-page .container form {
  max-width: 640px;
  margin: 32px auto 0;
}

@include until($tablet) {
  .is-form-page .container form {
    margin: 60px auto 0;
  }
}

.is-form-page .container form label.label {
    font-weight: 400;
    //font-family: "eurostile", sans-serif;
    font-size: 1.2rem;
    color: rgba(41, 51, 71, 0.65);
}

.is-form-page .container form input.is-large, .is-form-page .container form textarea.is-large, .is-form-page .container form select.is-large {
    font-size: 1.4rem;
}
.is-form-page .container form input, .is-form-page .container form textarea, .is-form-page .container form select {
    border-color: #E6ECF8;
    color: #141c3a;
    box-shadow: none;
    font-weight: 300;
}
</style>
